import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import Storage from './Storage';
import * as Sentry from '@sentry/nextjs';

export const apiUrl = (apiServer = true, apiMethod, scope) => {
  return `${apiServer ? process.env.NEXT_PUBLIC_API_URL : process.env.NEXT_PUBLIC_BASE_URL}/api/${scope}/${apiMethod}`;
}

export const WSUrl = () => {
  if (typeof window === 'undefined') return;
  return process.env.NEXT_PUBLIC_API_URL.replace(/^http/, 'ws')
    + '/cable'
    + '?token=' + localStorage.getItem('pnthn-client:token');
}

export const getUrlParameter = (sParam, location) => {
  if (typeof window === 'undefined') return;
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
}

export const sendRequest = async ({token, apiServer = true, method, scope = 'v1', headers = {}, data, type = 'GET', silent = false, onProgress, responseType = 'json'}) => {
  const serverSide = typeof window === 'undefined';
  if (!token && !serverSide) {
    token = localStorage.getItem('pnthn-client:access_token');
  }
  if (token) {
    const decoded = jwt_decode(token);
    const now = dayjs().unix();
    if (decoded.exp < now) {
      const refreshToken = localStorage.getItem('pnthn-client:refresh_token');
      try {
        const response = await axios.post(apiUrl(`sessions/refresh/${refreshToken}`, scope));
        if (response) {
          localStorage.setItem('pnthn-client:access_token', response.access_token);
          // localStorage.setItem('pnthn-client:refresh_token', response.data.refresh_token);
          Storage.saveData('access_token', response.access_token);
          // Storage.saveData('refresh_token', response.data.refresh_token);
          token = response.data.access_token;
        }
      } catch (err) {
        if (err.response.status === 401) {
          token = localStorage.getItem('pnthn-client:access_token');
        }
      }
    }
  }
  return new Promise((resolve, reject) => {
    const requestType = type.toUpperCase();
    const handleError = (response) => {
      if (!serverSide) {
        if (response.status === 401) {
          if (!method.includes('logout')) {
            triggerEvent('unauthorized');
          }
        } else if (response.status === 403) {
          // triggerEvent('unauthorized');
        } else if (response.status === 404) {
          resolve({ result: null, status: 404 });
        } else if (response.status === 503) {
          if (silent) {
            return false;
          }
          triggerEvent('showSnackbar', [{text: 'Network error, please try again later.', type: 'error'}]);
        } else {
          if (silent) {
            return false;
          } else if (response.error_message) {
            triggerEvent('showSnackbar', [{text: response.error_message, type: 'error'}]);
          } else if (response.data?.error_message && response.data?.errors) {
            const error = `${response.data?.errors[0].message} - ${response.data?.errors[0].param}`;
            triggerEvent('showSnackbar', [{text: `${response.data?.error_message}: ${error}`, type: 'error'}]);
          } else if (response.data?.error_message) {
            triggerEvent('showSnackbar', [{text: response.data?.error_message, type: 'error'}]);
          } else if (response.data?.errors) {
            response.data?.errors.forEach((element, i) => {
              triggerEvent('showSnackbar', [{text: `${response.data.errors[i].message} ${response.data.errors[i].param}`, type: 'error'}]);
            });
          } else {
            triggerEvent('showSnackbar', [{text: response.data?.error, type: 'error'}]);
          }
        }
      }
    }
    if (!silent) {
      triggerEvent('addLoad');
    }
    if (token && !headers['Authorization']) {
      headers['Authorization'] = 'Bearer ' + token;
    }
    axios({
      url: apiUrl(apiServer, method, scope),
      method: requestType,
      data: requestType === 'GET' ? null : data,
      params: requestType === 'GET' ? data : null,
      headers: headers,
      responseType: responseType,
      onUploadProgress: e => {
        if (onProgress) {
          onProgress(e.loaded / e.total);
        }
      }
    })
      .then(response => {
        if (!silent && !serverSide) {
          triggerEvent('removeLoad');
        }
        if (response.status >= 200 && response.status < 400) {
          resolve(response.data);
        } else {
          handleError(response);
          reject(response.data);
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        if (!silent && !serverSide) {
          triggerEvent('removeLoad');
        }
        let error = '';
        if (err.response) {
          handleError(err.response);
          error = err.response.data;
        }
        reject(error);
      })
  });
}

export const triggerEvent = (name, data) => {
  if (typeof window === 'undefined') return;
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(name, {detail: data});
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  document.dispatchEvent(event);
}

export const triggerNativeEvent = (elem, name) => {
  if (typeof window === 'undefined') return;
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  elem.dispatchEvent(event);
}

export const triggerClick = (elem) => {
  if (typeof window === 'undefined') return;
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent('click',true,true,window,0,0,0,0,0,false,false,false,false,0,null);
  elem.dispatchEvent(event);
}

export const eventOn = (name, handler, parent) => {
  if (typeof window === 'undefined') return;
  parent = parent || document;
  if (handler) {
    parent.addEventListener(name, handler);
  }
}

export const eventOff = (name, handler, parent) => {
  if (typeof window === 'undefined') return;
  parent = parent || document;
  if (handler) {
    parent.removeEventListener(name, handler);
  }
}

export const isIOS = () => {
  if (typeof window === 'undefined') return;
  return (
    (!window.MSStream && /iPad|iPhone|iPod/i.test(navigator.userAgent)) ||
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const formatedAmountString = (value, precision = 0, separator = ',') => {
  let num = Number(value);
  if (isNaN(num)) {
    num = 0;
  }
  const str = num.toFixed(precision);
  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const redirectUnauthorized = (req, res) => {
  if (!req.session.get('token')) {
    res.setHeader('location', '/');
    res.statusCode = 302;
    res.end();
    return {
      props: {}
    }
  }
}

export const isMobile = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  return window.innerWidth <= 600 && !!(navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    || navigator.userAgent.match(/Opera Mini/i)
    || navigator.userAgent.match(/IEMobile/i)
  );
};