import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from '../../sass/components/common/LoadIndicator.module.scss';

export default class LoadIndicator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
      text: props.text || null
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.text !== this.props.text) {
      this.setState({ text: this.props.text });
    }
  }

  render = () => {
    return (
      <div
        className={classnames(styles.loadIndicator, {
          [styles.hidden]: this.state.hidden,
        })}
      >
        <CSSTransition
          in={this.props.show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div className={styles.overlay}>
            <div className={styles.loadingContainer}>
              <div className={styles.spinner} />
              {this.state.text && <div className={styles.text}>{this.state.text}</div>}
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}
