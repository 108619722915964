import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../utils/global';

import styles from '../../sass/components/common/SidebarOverlay.module.scss';

class SidebarOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
      show: false,
      content: null,
      left: props.left,
      right: props.right,
      isMobile: false,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      eventOn('resize', (e) => {
        this.setState({ isMobile: window.innerWidth <= 600 });
      }, window);

      this.setState({ isMobile: window.innerWidth <= 600 });
    }
    if (this.props.global) {
      eventOn('showSidebarOverlay', this.showSidebarOverlay);
      eventOn('hideSidebarOverlay', this.hideSidebarOverlay);
    }
  }

  componentWillUnmount = () => {
    eventOff('resize', (e) => {
      this.setState({ isMobile: window.innerWidth <= 600 });
    }, window);
    if (this.props.global) {
      eventOff('showSidebarOverlay', this.showSidebarOverlay);
      eventOff('hideSidebarOverlay', this.hideSidebarOverlay);
    }
  }

  showSidebarOverlay = (e) => {
    document.body.style.overflow = 'hidden';
    const details = e.detail[0];
    this.setState({
      show: true,
      hidden: false,
      content: details.content,
      left: details.left || this.props.left,
      right: details.right || this.props.right,
    });
  }

  hideSidebarOverlay = () => {
    document.body.style.overflow = null;
    this.setState({ show: false });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.hideSidebarOverlay();
    }
  }

  render = () => {
    const { hidden, show, content, left, right } = this.state;

    return (
      <div
        className={classnames({
          [styles.sidebarOverlay]: true,
          [styles.hidden]: hidden,
        })}
      >
        <div
          onClick={this.onOverlayClick}
          className={styles.overlay}
        />
        <div
          className={classnames(styles.close, {
            [styles.active]: show,
            [styles.mobile]: this.state.isMobile,
          })}
          onClick={this.onOverlayClick}
        />
        <CSSTransition
          in={show}
          timeout={350}
          classNames={classnames({
            'slide-right': left || !right,
            'slide-left': right,
          })}
          onEnter={() => this.setState({ hidden: false })}
          onExited={() => this.setState({ hidden: true })}
        >
          <div
            className={classnames({
              [styles.sidebarPane]: true,
              [styles.left]: left || !right,
              [styles.right]: right,
              [styles.mobile]: this.state.isMobile,
            })}
          >
            <div className={styles.contentContainer}>{content}</div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default SidebarOverlay;
