import React from 'react';
import { withRouter } from 'next/router';

import styles from '../sass/components/TheFooter.module.scss';

class TheFooter extends React.PureComponent {

  render = () => {
    return (
      <div className={styles.footer}>
        <div className='container'>
          <div className={styles.flex}>
            <div className={styles.copyrights}>©2022 PNTHN. All rights reserved.</div>
            <div className={styles.contacts}>
              <a className={styles.opensea} href='https://opensea.io/collection/neonpantheon' rel='noopener noreferrer' />
              <a className={styles.discord} href='https://discord.gg/neonpantheon' rel='noopener noreferrer' />
              <a className={styles.twitter} href='https://twitter.com/NeonPantheonNFT' rel='noopener noreferrer' />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(TheFooter);
