import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../utils/global';

import styles from '../../sass/components/common/ContentPopup.module.scss';

class ContentPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      hidden: true,
      title: null,
      content: null,
      callback: null,
      cancelText: null,
      confirmText: null,
      overlayBlock: false,
      preventDefault: false,
      videoPlayer: false,
      blockingContent: false,
      width: null,
    };
  }

  componentDidMount = () => {
    eventOn('showContentPopup', this.showContentPopup);
    eventOn('hideContentPopup', this.hideContentPopup);
  }

  componentWillUnmount = () => {
    eventOff('showContentPopup', this.showContentPopup);
    eventOff('hideContentPopup', this.hideContentPopup);
  }

  showContentPopup = (e) => {
    const details = e.detail[0];
    this.setState({
      show: true,
      hidden: false,
      title: details.title,
      content: details.content,
      callback: details.callback,
      cancelText: details.cancelText,
      confirmText: details.confirmText,
      preventDefault: details.preventDefault,
      overlayBlock: details.overlayBlock,
      width: details.width || 360
    });
  }

  hideContentPopup = () => {
    this.setState({
      show: false,
    });
  }

  onOverlayClick = (e) => {
    if (this.state.overlayBlock) {
      return;
    } else {
      if (e.target === e.currentTarget) {
        this.onClose(false);
      }
    }
  }

  onClose = (res) => {
    this.setState({
      show: false,
    });
  }

  onConfirm = () => {
    if (this.props.global) {
      if (this.state.callback) {
        this.state.callback(true);
      }
      this.setState({show: false});
    } else {
      this.props.callback(true);
    }
  }

  handleMouseDown = (e) => {
    e.preventDefault();
  }

  render = () => {
    const { hidden, show, title, content, confirmText, cancelText, width } = this.state;

    return (
      <div
        className={classnames({
          [styles.contentPopup]: true,
          [styles.hidden]: hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className={classnames({
              [styles.overlay]: true,
            })}
          >
            <div
              className={classnames({
                [styles.popupWindow]: true,
              })}
              style={{ width: `${width}px` }}
            >
              <div className={styles.close} onClick={this.onClose} />
              {title ?
                <div className={styles.popupTitle}>{title}</div>
              : null}
              <div className={styles.contentContainer}>{content}</div>
              {(confirmText || cancelText) &&
                <div className={styles.popupControls}>
                  {cancelText &&
                  <div
                    onClick={this.onClose}
                    className={classnames(styles.popupControl, styles.cancel)}
                  >
                    {cancelText || this.props.localization.cancel}
                  </div>
                  }
                  {confirmText &&
                  <div
                    onClick={(e) => this.onConfirm(e)}
                    className={classnames(styles.popupControl, styles.confirm)}
                    onMouseDown={(e) => this.state.preventDefault && this.handleMouseDown(e)}
                  >
                    {confirmText || this.props.localization.confirm}
                  </div>
                  }
                </div>
              }
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default ContentPopup;
