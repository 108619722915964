import React from 'react';
import { Provider } from 'react-redux';
import { withRouter } from 'next/router';
import classnames from 'classnames';

import { WagmiWrap } from './WagmiWrap';
import TheFooter from './TheFooter';
import LoadIndicator from '../components/common/LoadIndicator';
import Snackbar from '../components/common/Snackbar';
import ContentPopup from '../components/common/ContentPopup';
import SidebarOverlay from '../components/common/SidebarOverlay';

import Storage from '../utils/Storage';
import { triggerEvent, eventOn, eventOff, sendRequest } from '../utils/global';
import { logoutAction } from '../utils/auth';

import styles from '../sass/components/BaseLayout.module.scss';

class BaseLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
      headerIsSticky: false,
      navOpen: false,
      loadIndicatorText: null,
      showMobileNotice: false,
    };
    this.loadCount = 0;
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
      eventOn('resize', this.setIsMobile, window);
      this.setIsMobile();

      if (window.innerWidth <= 600 && !localStorage.getItem('pnthn-client:mobileNoticed')) {
        this.setState({ showMobileNotice: true });
      }
    }

    const accessToken = localStorage.getItem('pnthn-client:access_token') || null;
    if (!Storage.getData('user') && accessToken) this.loadUser();
    if (!Storage.getData('profileImages')) Storage.loadData('profileImages');

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
    eventOn('removeAllLoads', this.removeAllLoads);
    eventOn('updateLoadText', this.updateLoadText);
    eventOn('unauthorized', this.logout);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    eventOff('resize', this.setIsMobile(), window);
  }

  setIsMobile = () => {
    Storage.setSetup('isMobile', window.innerWidth <= 600);
  }

  loadUser = () => {
    sendRequest({
      type: 'GET',
      method: 'users/me',
      silent: true,
    }).then(result => {
      Storage.setData('user', result);
      this.requestUnreadEndorsements();
    }).catch(error => {
      if (error.error === 'Forbidden' && this.props.refreshToken) {
        this.refreshAccessToken(this.props.refreshToken);
      } else {
        this.disconnectUser();
      }
    });
  }

  disconnectUser = async () => {
    await logoutAction(); // removes tokens, destroys sessions
  }

  refreshAccessToken = (refreshToken) => {
    sendRequest({
      type: 'POST',
      method: `sessions/refresh/${refreshToken}`
    }).then(result => {
      this.loadUser();
    }).catch(error => {
      this.disconnectUser();
    });
  }

  requestUnreadEndorsements = () => {
    sendRequest({
      type: 'GET',
      method: 'endorsements/unread',
      silent: true,
    }).then(result => {
      Storage.setData('unreadEndorsements', result);
    }).catch(error => {
      console.log('error', error);
    });
  }

  handleScroll = () => {
    const scrollPosition = document.documentElement.scrollTop;
    const isSticky = this.state.headerIsSticky;
    if (scrollPosition > 25 && !isSticky) {
      this.setState({ headerIsSticky: true });
    } else if (scrollPosition < 25 && isSticky) {
      this.setState({ headerIsSticky: false });
    }
  }

  logout = async () => {
    await logoutAction();
    triggerEvent('showSnackbar', [{text: 'Your access token expired, please re-login'}]);
  }

  addLoad = (e) => {
    this.loadCount++;
    const text = e.detail ? e.detail[0]?.text : null;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0, loadIndicatorText: text});
    }
  }

  updateLoadText = (e) => {
    const text = e.detail ? e.detail[0]?.text : null;
    this.setState({ loadIndicatorText: text });
  }

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0, loadIndicatorText: null});
    }
  }

  removeAllLoads = () => {
    this.loadCount = 0;
    this.setState({showLoadIndicator: false, loadIndicatorText: null});
  }

  toggleNav = () => {
    this.setState(prevState => ({
      navOpen: !prevState.navOpen
    }));
  }

  closeMobileNotice = () => {
    localStorage.setItem('pnthn-client:mobileNoticed', true);
    this.setState({ showMobileNotice: false });
  }

  renderMobileNotice = () => {
    return (
      <div className={styles.mobileNotice}>
        <div className={styles.text}>
          Account creation is not recommended on mobile right now.
        </div>
        <div className={styles.close} onClick={this.closeMobileNotice} />
      </div>
    );
  }

  render = () => {
    const hideNav = [
      '/admin',
      '/admin/login',
      '/admin/users',
      '/admin/organizations',
      '/wizard/step/[id]',
      '/callback/twitter',
      '/callback/discord',
    ].includes(this.props.router.pathname);

    const showMobileNotice = this.state.showMobileNotice && ['/wizard/step/[id]'].includes(this.props.router.pathname);

    return (
      <Provider store={Storage.getStore()}>
        <div
          className={classnames(styles.baseLayout,
            {
              [styles.sticky]: this.state.headerIsSticky,
              [styles.navOpen]: this.state.navOpen,
              [styles.withMobileNotice]: showMobileNotice,
            }
          )}
        >
          <LoadIndicator show={this.state.showLoadIndicator} text={this.state.loadIndicatorText} />
          <Snackbar />
          <ContentPopup global />
          <SidebarOverlay global />
          { showMobileNotice && this.renderMobileNotice() }
          { this.props.children }
          { hideNav ? null : <TheFooter /> }
        </div>
      </Provider>
    );
  }
}

export default withRouter(BaseLayout);
