import Storage from './Storage';
import { useDisconnect } from 'wagmi';

export const loginAction = async (data) => {
  localStorage.setItem('pnthn-client:access_token', data.access_token);
  localStorage.setItem('pnthn-client:refresh_token', data.refresh_token);

  Storage.saveData('access_token', data.access_token);
  Storage.saveData('refresh_token', data.refresh_token);

  await fetch('/api/login', {
    method: 'POST',
    body: JSON.stringify({
      access_token: data.access_token,
      refresh_token: data.refresh_token
    }),
  });

  return true;
}

export const logoutAction = async () => {
  localStorage.removeItem('pnthn-client:access_token');
  localStorage.removeItem('pnthn-client:refresh_token');
  Storage.removeData('access_token');
  Storage.removeData('refresh_token');
  Storage.removeData('user');
  Storage.removeData('unreadEndorsements');
  Storage.removeData('profileImages');

  await fetch('/api/logout');

  return true;
}
