import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import BaseLayout from '../components/BaseLayout';
import { WagmiWrap } from '../components/WagmiWrap';

import '../sass/base.scss';

class PNTHNApp extends App {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render = () => {
    const { Component, pageProps } = this.props;
    const env = process?.env?.NODE_ENV;

    return (
      <WagmiWrap>
        <Head>
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
        </Head>
        <BaseLayout>
          <Component {...pageProps} />
          {env === 'production' && 
          <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.NEXT_PUBLIC_GTAG_ID}');
            `}
          </Script>
          </>
          }
        </BaseLayout>
      </WagmiWrap>
    );
  }
}

export default PNTHNApp;
